// If you want to override variables do it here
@import "variables";

// If you want to add something do it here
@import "custom";

@import "launchevent";

@import "thankspage";

@import "orderreceivedthankspage";

@import "~bootstrap/scss/bootstrap";

//@import "~bootstrap/scss/functions";
//@import "~bootstrap/scss/_variables";
//@import "~bootstrap/scss/_mixins";

//@import "~bootstrap/scss/_grid.scss";
//@import "~bootstrap/scss/_images.scss";
